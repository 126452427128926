/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

/** Union of primitives types. */
type Primitive =
  | string
  | Function
  | number
  | boolean
  | symbol
  | undefined
  | null;

export function makeHashMap<T>(value: T): Partial<T> {
  return value;
}

// const almostMap = { a: [], b: [] };

// const map = makeHashMap(almostMap);

// @@ts-expect-error "must be optional"
// const _ = map["a"].length;

export type Modify<T, R> = Omit<T, keyof R> & R;

export function isNotNull<T>(value: T | null | undefined): value is T {
  return value != null;
}

type OmitDistributive<T, K extends PropertyKey> = T extends any
  ? T extends object
    ? Id<OmitRecursively<T, K>>
    : T
  : never;
type Id<T> = {} & { [P in keyof T]: T[P] }; // Cosmetic use only makes the tooltips expad the type can be removed

/**
 * type A = {
    keyToKeep: string
    keyToOmit: string
    nested: {
        keyToKeep: string
        keyToOmit: string
    }
    nestedOptional?: {
        keyToKeep: string
        keyToOmit: string
    }
}

type Result = OmitRecursively<A, 'keyToOmit'>
 */
export type OmitRecursively<T, K extends PropertyKey> = Omit<
  { [P in keyof T]: OmitDistributive<T[P], K> },
  K
>;

export type DeepOmitTypename<T> = OmitRecursively<T, "__typename">;

type ValidationErrorsArray<T extends any[]> = {
  [P in keyof T]: ValidationErrorsType<T[P]>;
};

export type ValidationErrorsType<T> = Partial<{
  [P in keyof T]: T[P] extends Primitive
    ? string | undefined
    : T[P] extends any[]
    ? ValidationErrorsArray<T[P]>
    : ValidationErrorsType<T[P]>;
}>;

type Impossible<K extends keyof any> = {
  [P in K]: never;
};

export type NoExtraProperties<T, U extends T = T> = U extends Array<infer V>
  ? Array<NoExtraProperties<V>>
  : U & Impossible<Exclude<keyof U, keyof T>>;

// Taken from https://stackoverflow.com/questions/55539387/deep-omit-with-typescript

/** Deeply omit members of an array of interface or array of type. */
type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};

/** Deeply omit members of an interface or type. */
export type DeepOmit<T, K> = T extends Primitive
  ? T
  : {
      [P in Exclude<keyof T, K>]: T[P] extends infer TP //extra level of indirection needed to trigger homomorhic behavior // distribute over unions
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
          ? DeepOmitArray<TP, K> // Array special handling
          : DeepOmit<TP, K>
        : never;
    };

/** Deeply omit members of an array of interface or array of type, making all members optional. */
type PartialDeepOmitArray<T extends any[], K> = Partial<{
  [P in Partial<keyof T>]: Partial<PartialDeepOmit<T[P], K>>;
}>;

/** Deeply omit members of an interface or type, making all members optional. */
type PartialDeepOmit<T, K> = T extends Primitive
  ? T
  : Partial<{
      [P in Exclude<keyof T, K>]: T[P] extends infer TP //extra level of indirection needed to trigger homomorhic behavior // distribute over unions
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
          ? PartialDeepOmitArray<TP, K> // Array special handling
          : Partial<PartialDeepOmit<TP, K>>
        : never;
    }>;

// export type DeepOmitTypename<T> = DeepOmit<T, "__typename">;
