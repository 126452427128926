import React, { useCallback, useState } from "react";

import Image from "next/image";
import Link from "next/link";

import { Routes } from "@/constants";
import { isDOM } from "@/lib/isDom";

import { COOKIE_LOGO_MOBILE_SIZE, COOKIE_LOGO_SIZE, TestId } from "./constants";
import {
  isCookieConsentGiven,
  sendGtmConsentGiven,
  setCookieConsent,
} from "./utils";

import styles from "./styles.module.scss";

import cookieImage from "./assets/cookie.png";

export const CookiePopUp = () => {
  const [isCookiePopUpOpened, setIsCookiePopUpOpened] = useState(
    isDOM && !isCookieConsentGiven()
  );

  const handleAcceptCookies = useCallback(() => {
    setIsCookiePopUpOpened(false);
    setCookieConsent();
    sendGtmConsentGiven();
  }, []);

  if (!isCookiePopUpOpened) {
    return null;
  }

  return (
    <div className={styles.root} data-testid={TestId.Root}>
      <div className={styles.inner}>
        <div className={styles.image}>
          <Image
            alt="Cookies"
            height={COOKIE_LOGO_SIZE}
            placeholder="blur"
            src={cookieImage}
            width={COOKIE_LOGO_SIZE}
          />
        </div>
        <div className={styles.content}>
          <span className={styles.header}>
            <span className={styles.title}>
              We use cookies to provide a better experience
            </span>

            <div className={styles.imageMobile}>
              <Image
                alt="Cookies"
                height={COOKIE_LOGO_MOBILE_SIZE}
                src={cookieImage}
                width={COOKIE_LOGO_MOBILE_SIZE}
              />
            </div>
          </span>
          <p>
            Cookies help keep the site running and improve your experience with
            personalised content. <br />
            See our Cookie Policy for{" "}
            <Link href={Routes.CookiePolicy} prefetch={false}>
              <a aria-label="Learn more about cookies">more details</a>
            </Link>
            .
          </p>
          <div className={styles.actionButtons}>
            <button
              className={styles.acceptButton}
              data-testid={TestId.AcceptButton}
              onClick={handleAcceptCookies}
              type="button"
            >
              Accept &amp; close
            </button>
            <Link href={Routes.CookiePolicy} prefetch={false}>
              <a
                aria-label="Learn more about cookies"
                className={styles.learnMoreButton}
                data-testid={TestId.LearnMoreButton}
              >
                Learn more
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
