const GTM_AUTH = process.env.GTM_AUTH;
const GTM_ENV = process.env.GTM_ENV;
/**
 * @deprecated move to GA4
 */
export const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_ENV}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-M9BLWCJ');`;
/**
 * @deprecated move to GA4
 */
export const GTM_IFRAME_SRC = `https://www.googletagmanager.com/ns.html?id=GTM-M9BLWCJ&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_ENV}&gtm_cookies_win=x`;

// Google Analytic 4
export const GA4_SRC = `https://www.googletagmanager.com/gtag/js?id=${process.env.GTAG_ANALYTICS_ID}`;
export const GA4_SCRIPT = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.GTAG_ANALYTICS_ID}');`;
