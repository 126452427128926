import { forward } from "effector";
import Cookies from "js-cookie";
import Router from "next/router";

import getApolloClient from "../../../lib/init-apollo-client";
import {
  AuthGetUserInfoDocument,
  AuthGetUserInfoQuery,
  AuthGetUserInfoQueryVariables,
  BindAnonymousSessionDocument,
  BindAnonymousSessionMutation,
  BindAnonymousSessionMutationVariables,
  GetAnonymousSessionIdDocument,
  GetAnonymousSessionIdQuery,
  GetAnonymousSessionIdQueryVariables,
} from "../graphql/queries/auth.generated";
import {
  fetchAnonymousUserFx,
  fetchUserFx,
  logoutFx,
  onLogOutEvent,
  refetchAuthInfoEvent,
} from ".";
import {
  LogoutDocument,
  LogoutMutation,
  LogoutMutationVariables,
} from "./index.generated";
const SESSION_COOKIE_NAME = "session";

fetchUserFx.use(async () => {
  try {
    if (Cookies.get("refreshToken") == null) {
      // eslint-disable-next-line unicorn/error-message
      throw new Error("");
    }
    const userInfoResult = await getApolloClient()
      .query<AuthGetUserInfoQuery, AuthGetUserInfoQueryVariables>({
        query: AuthGetUserInfoDocument,
        fetchPolicy: "network-only",
        errorPolicy: "ignore",
      })
      .catch(() => null);

    if (userInfoResult?.error) {
      throw new Error(userInfoResult.error.message);
    }

    const info = userInfoResult?.data.account?.info;
    if (info == null) {
      throw new Error("User info is null");
    }
    return info;
  } catch {
    // eslint-disable-next-line unicorn/error-message
    throw new Error("");
  }
});

fetchAnonymousUserFx.use(async (): Promise<string | undefined> => {
  // try {
  const anonymousSessionFromCookie = Cookies.get(SESSION_COOKIE_NAME);
  if (anonymousSessionFromCookie != null && anonymousSessionFromCookie !== "") {
    await getApolloClient().mutate<
      BindAnonymousSessionMutation,
      BindAnonymousSessionMutationVariables
    >({
      mutation: BindAnonymousSessionDocument,
      variables: {
        session: anonymousSessionFromCookie,
      },
    });

    return anonymousSessionFromCookie;
  } else {
    const result = await getApolloClient().query<
      GetAnonymousSessionIdQuery,
      GetAnonymousSessionIdQueryVariables
    >({ query: GetAnonymousSessionIdDocument });
    const session = result.data.auth?.session;

    if (session != null) {
      // eslint false alarm
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Cookies.set(SESSION_COOKIE_NAME, session);
    }

    return session ?? undefined;
  }
  // } catch (error) {
  //   return error instanceof Error ? error : new Error(String(error));
  // }
});

logoutFx.use(async () => {
  const apolloClient = getApolloClient();
  try {
    await apolloClient.mutate<LogoutMutationVariables, LogoutMutation>({
      mutation: LogoutDocument,
    });
  } catch {
  } finally {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove(SESSION_COOKIE_NAME);
    await apolloClient.clearStore();
    await Router.replace("/");
  }
});

forward({ from: onLogOutEvent, to: logoutFx });
forward({
  from: logoutFx.doneData,
  to: fetchAnonymousUserFx,
});

forward({ from: refetchAuthInfoEvent, to: fetchUserFx });

forward({
  from: fetchUserFx.fail,
  to: fetchAnonymousUserFx,
});
