import {
  Offer_Api_RentPlanEnum,
  Offer_Api_Rto_RentPlanEnum,
} from "@/graphql-schema-types.generated";

type RentPlanData<T> = Record<
  "ESSENTIAL" | "SECURED",
  {
    button: string;
    buttonActive: string;
    id: string;
    note: string;
    list: string[];
    plan: T;
    popular?: boolean;
    title: string;
  }
>;

const COMMON_RENT_PLANS_DATA = {
  ESSENTIAL: {
    id: "essential",
    title: "Essential",
    note: "E.g. If the rent on your property is £1000p/m, we’ll deduct just £20 for:",
    list: [
      "<b>Eviction & Legal Expenses Insurance</b> up to £25,000",
      "Rent collection with same day payouts, protected by Money Shield",
      "Automatic reminders and late payment chasers for tenants",
      "Rent payment updates in your dashboard & income analytics",
      "Access to Advanced Rent - the ability to receive up to 3 months of rent early",
    ],
    button: "Get for 2% of rent",
    buttonActive: "Applied for 2% of rent",
  },
  SECURED: {
    id: "secured",
    title: "Secured",
    note: "E.g. If the rent on your property is £1000 p/m, we’ll deduct just £50 for:",
    list: [
      "<b>Everything</b> included in the essential package",
      "<b>Rent Guarantee Insurance</b> cover up to £2,500 per month. Make sure your bills and outgoings are covered from the first missed or part payment.",
      "<b>Home Emergency Insurance</b> cover up to £1,000 per emergency. This covers plumbing issues, broken boilers, failure of gas or electric and more. We also cover overnight accommodation for your tenant, so nothing needs to come out of your pocket.",
    ],
    button: "Get for 5% of rent",
    buttonActive: "Applied for 5% of rent",
    popular: true,
  },
};

export const RENT_PLANS_DATA: RentPlanData<Offer_Api_RentPlanEnum> = {
  ESSENTIAL: {
    ...COMMON_RENT_PLANS_DATA.ESSENTIAL,
    plan: Offer_Api_RentPlanEnum.RentPlanEssential,
  },
  SECURED: {
    ...COMMON_RENT_PLANS_DATA.SECURED,
    plan: Offer_Api_RentPlanEnum.RentPlanSecured,
  },
};

export const RTO_RENT_PLANS_DATA: RentPlanData<Offer_Api_Rto_RentPlanEnum> = {
  ESSENTIAL: {
    ...COMMON_RENT_PLANS_DATA.ESSENTIAL,
    plan: Offer_Api_Rto_RentPlanEnum.RentPlanEssential,
  },
  SECURED: {
    ...COMMON_RENT_PLANS_DATA.SECURED,
    plan: Offer_Api_Rto_RentPlanEnum.RentPlanSecured,
  },
};

export const RENT_PLANS = [RENT_PLANS_DATA.ESSENTIAL, RENT_PLANS_DATA.SECURED];
export const RTO_RENT_PLANS = [
  RTO_RENT_PLANS_DATA.ESSENTIAL,
  RTO_RENT_PLANS_DATA.SECURED,
];
