import { QueryClient } from "react-query";

import { isDOM } from "@/lib/isDom";

const config = {
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 10 * 60 * 1000 /* 10min */,
      staleTime: 2 * 60 * 1000 /* 2min */,
    },
  },
};

let queryClient: QueryClient | undefined;

export default function initQueryClient() {
  if (!isDOM) {
    return new QueryClient(config);
  }

  if (!queryClient) {
    queryClient = new QueryClient(config);
  }

  return queryClient;
}
