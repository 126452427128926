import { DeepOmit } from "./magic-types";

function omitDeep<T extends object, K extends keyof T | string>(
  obj: T,
  key: K
): DeepOmit<T, K> {
  const keys = Object.keys(obj);
  const newObj: T = {} as any;
  for (const i of keys) {
    if (i !== key) {
      const val = obj[i];
      if (val instanceof Date) {
        newObj[i] = val;
      }
      if (Array.isArray(val)) {
        newObj[i] = omitDeepArrayWalk(val, key);
      } else if (typeof val === "object" && val != null) {
        newObj[i] = omitDeep(val, key);
      } else {
        newObj[i] = val;
      }
    }
  }
  return newObj as DeepOmit<T, K>;
}

function omitDeepArrayWalk(arr, key) {
  return arr.map((val) => {
    if (Array.isArray(val)) {
      return omitDeepArrayWalk(val, key);
    } else if (typeof val === "object") {
      return omitDeep(val, key);
    }
    return val;
  });
}

export const stripTypenames = <T extends { __typename: string }>(v: T) =>
  omitDeep(v, "__typename");
