import { useEvent } from "effector-react";

import {
  failure,
  hasData,
  initialized,
  maybeData,
  pending,
  RemoteData,
  success,
} from "../../../lib/remote";
import { rootDomain } from "../../effector";
import { AccountInfoFieldsFragment } from "../graphql/queries/auth.generated";

export type UserStore = RemoteData<AccountInfoFieldsFragment, null>;
export type AnonymousUserStore = RemoteData<string | undefined>;

export const authDomain = rootDomain.createDomain("auth");

export const onLogOutEvent = authDomain.createEvent();
export const useOnLogOutEvent = () => useEvent(onLogOutEvent);
const onLoginEvent = authDomain.createEvent<AccountInfoFieldsFragment>();
export const useOnLoginEvent = () => useEvent(onLoginEvent);
export const refetchAuthInfoEvent = authDomain.createEvent();
export const useRefetchAuthInfoEvent = () => useEvent(refetchAuthInfoEvent);

export const fetchUserFx = authDomain.createEffect<
  void,
  AccountInfoFieldsFragment
>();

export const fetchAnonymousUserFx = authDomain.createEffect<
  void,
  string | undefined
>();

export const logoutFx = authDomain.createEffect<void, void>();

// const clearApolloStoreFx = authDomain.createEffect(() => {
//   // apolloClient.onClearStore
// });

export const $anonymous = authDomain
  .createStore<AnonymousUserStore>(initialized())
  .on(fetchAnonymousUserFx, (state) => pending(state))
  .on(fetchAnonymousUserFx.fail, (state, error) => failure(error, state))
  .on(fetchAnonymousUserFx.doneData, (_, payload) => success(payload))
  .reset(onLogOutEvent);

export const $user = authDomain
  .createStore<UserStore>(
    initialized() /* Initialized — user is not logged in */
  )
  .on(onLoginEvent, (_state, payload) => success(payload))
  .on(fetchUserFx, (state) => pending(state))
  .on(fetchUserFx.doneData, (_state, payload) => success(payload))
  /* user is not logged in */
  .on(fetchUserFx.fail, (state, payload) => failure(null))
  .on(onLogOutEvent, () => failure(null));

export const $anonymousSession = $anonymous.map((anonymous) =>
  maybeData((d) => d, undefined, anonymous)
);

export const $isLoggedIn = $user.map((auth) => hasData(auth));
export const $myId = $user.map((auth) =>
  maybeData((d) => d.id, undefined, auth)
);
