/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  ApolloClient,
  ApolloContextValue,
  ApolloProvider,
  getApolloContext,
} from "@apollo/client";
import React, { ComponentType, useContext, useState } from "react";

import initApollo from "./init-apollo-client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withApolloClient = (App: ComponentType<any>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function Apollo(props: any) {
    const apolloClientParentContext = useContext(
      getApolloContext()
    ) as ApolloContextValue | null;
    if (
      process.env.NODE_ENV === "development" &&
      apolloClientParentContext != null
    ) {
      // eslint-disable-next-line no-console
      console.warn("ApolloProvider exists in the component tree already");
    }
    const [apolloClient] = useState<ApolloClient<unknown>>(
      apolloClientParentContext?.client ??
        initApollo(props.pageProps?.apolloState)
    );
    return (
      <ApolloProvider client={apolloClient}>
        <App {...props} />
      </ApolloProvider>
    );
  }

  return Apollo;
};
export default withApolloClient;
