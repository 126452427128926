import Cookies from "js-cookie";

const CONSENT_COOKIE_EXPIRES_IN_DAYS = 99_999;
const CONSENT_COOKIE_NAME = "COOKIES_ACCEPTED";
const CONSENT_COOKIE_VALUE = "1";

export const isCookieConsentGiven = () =>
  Cookies.get(CONSENT_COOKIE_NAME) === CONSENT_COOKIE_VALUE;

export const setCookieConsent = () =>
  Cookies.set(CONSENT_COOKIE_NAME, CONSENT_COOKIE_VALUE, {
    expires: CONSENT_COOKIE_EXPIRES_IN_DAYS,
  });
