import { isDOM } from "@/lib/isDom";

export const logPageView = (url: string) => {
  if (isDOM) {
    window.dataLayer?.push({
      event: "VirtualPageview",
      virtualPageURL: url,
    });
  }
};
