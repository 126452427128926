// import * as websocket from "websocket";
import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { GraphQLError } from "graphql";
import fetch from "isomorphic-unfetch";
import Cookies from "js-cookie";
import { SubscriptionClient } from "subscriptions-transport-ws";

import { onLogOutEvent } from "../core/Auth/effector";
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from "./init-apollo-client.generated";
import { isNotNull } from "./magic-types";
import { stripTypenames } from "./strip-typenames";

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;
export let cache: InMemoryCache;

let isRefreshing = false;
let pendingRequests: Array<() => void> = [];

const resolvePendingRequests = () => {
  for (const callback of pendingRequests) {
    callback();
  }
  pendingRequests = [];
};

if (!process.browser) {
  global.fetch = fetch;
}

const getAccessToken = () =>
  process.browser ? Cookies.get("accessToken") : undefined;

const getRefreshToken = () =>
  (Cookies.get("refreshToken") ?? "").replace(/[^\w+.=-]/g, "");

const getSession = () => {
  const accessToken = getAccessToken();

  return process.browser
    ? !accessToken
      ? Cookies.get("session")
      : undefined
    : undefined;
};

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  // if (operation.variables) {
  operation.variables = stripTypenames(
    operation.variables as typeof operation.variables & { __typename: string }
  );
  // }
  return forward(operation).map((data) => data);
});

const RETRYABLE_ERRORS = new Set(["not_authenticated", "invalid_data"]);

const shouldRetry = (error: GraphQLError) => {
  const code = error.extensions?.code;
  return Cookies.get("refreshToken") != null && code != null
    ? RETRYABLE_ERRORS.has(code as string)
    : false;
};

export default function getApolloClient(
  apolloState?: NormalizedCacheObject | undefined
) {
  if (process.browser && apolloClient != null) {
    return apolloClient;
  }

  const uri = process.browser
    ? process.env.NEXT_API_URL
    : process.env.NEXT_API_URL_HTTPS;

  if (uri == null) {
    throw new Error("NEXT_API_URL and NEXT_API_URL_HTTPS must be set!");
  }

  let httpOrWsLink: ApolloLink;
  if (process.browser) {
    const wsClient = new SubscriptionClient(
      uri,
      {
        reconnect: process.browser,
        connectionParams: () => ({
          authToken: getAccessToken(),
          session: getSession(),
        }),
      },
      window.WebSocket
    );
    httpOrWsLink = new WebSocketLink(wsClient);
  } else {
    httpOrWsLink = new HttpLink({
      uri,
    });
  }

  const getNewToken = async () => {
    if (apolloClient != null) {
      try {
        const refresh_token = getRefreshToken();

        const { data } = await apolloClient.mutate<
          RefreshTokenMutation,
          RefreshTokenMutationVariables
        >({
          mutation: RefreshTokenDocument,
          variables: {
            refresh_token,
          },
        });

        if (data?.auth?.refresh?.success) {
          const { access_token, refresh_token } = data.auth.refresh;
          Cookies.set("accessToken", access_token);
          Cookies.set("refreshToken", refresh_token);
          // eslint-disable-next-line no-console
          console.info("Access token refreshed");
          return access_token;
        }
      } catch {
        onLogOutEvent();
      }
    }
    return undefined;
  };

  // const retryLink = new RetryLink({
  //   attempts: async (count, operation, error) => {
  //     if (shouldRetry(error)) {
  //       if (operation.operationName === "RefreshToken") {
  //         return false;
  //       }
  //       if (apolloClient != null) {
  //         try {
  //           const refresh_token = getRefreshToken();

  //           const { data } = await apolloClient.mutate<
  //             RefreshTokenMutation,
  //             RefreshTokenMutationVariables
  //           >({
  //             mutation: RefreshTokenDocument,
  //             variables: {
  //               refresh_token,
  //             },
  //           });

  //           if (data?.auth?.refresh?.success) {
  //             const { access_token, refresh_token } = data.auth.refresh;
  //             Cookies.set("accessToken", access_token);
  //             Cookies.set("refreshToken", refresh_token);
  //             // wsClient.sendMessage("", "connection_init", {
  //             //   authToken: getAccessToken(),
  //             //   session: getSession(),
  //             // });
  //             operation.setContext(
  //               // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //               ({ headers = {} }: { headers: any }) => ({
  //                 headers: {
  //                   ...headers,
  //                   authorization: `Bearer ${access_token}` || null,
  //                 },
  //               })
  //             );
  //           } else {
  //             throw new Error("Can't get access token");
  //           }
  //         } catch {
  //           await apolloClient.clearStore();
  //           Cookies.remove("accessToken");
  //           Cookies.remove("refreshToken");
  //           onLogOutEvent();
  //           return true;
  //         }
  //       }
  //       return false;
  //     }

  //     return true;
  //   },
  //   delay: { initial: 500, jitter: true },
  // });

  // const promoteLink = new ApolloLink((operation, forward) => forward(operation).map((data) => {
  //   // if (operation.operationName === "UserLogout") {
  //   //   wsClient.sendMessage("", "connection_init", {
  //   //     authToken: "",
  //   //   });
  //   // }
  //   if (data.errors && data.errors.length > 0 && shouldRetry(data.errors)) {
  //     throw data.errors;
  //   }
  //   return data;
  // }));

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (operation.operationName !== "RefreshToken" && shouldRetry(err)) {
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                getNewToken()
                  .then((accessToken) => {
                    // Store the new tokens for your auth link
                    resolvePendingRequests();
                    return accessToken;
                  })
                  // pendingRequests is intentionally modified inside the loop
                  // eslint-disable-next-line no-loop-func
                  .catch((error) => {
                    pendingRequests = [];
                    // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                  })
                  // isRefreshing is used as a global "mutex"
                  // eslint-disable-next-line no-loop-func
                  .finally(() => {
                    isRefreshing = false;
                  })
              ).filter(isNotNull);
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                // eslint-disable-next-line no-loop-func
                new Promise((resolve) => {
                  pendingRequests.push(() => resolve(undefined));
                })
              );
            }

            return forward$.flatMap(() => forward(operation));
          }
        }
      }
      if (networkError) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`);
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
      }
      return undefined;
    }
  );

  const authLink = setContext((_, { headers }) => {
    const token = getAccessToken();
    if (token != null) {
      return undefined;
    }

    return {
      headers: {
        ...headers,
        authorization: `bearer ${token}`,
      },
    };
  });

  cache = new InMemoryCache({
    typePolicies: {
      account_api_AccountContactOutput: {
        keyFields: false,
        merge: true,
      },
      account_api_AccountDetailsEnrichedOutput: {
        keyFields: false,
        merge: true,
      },
      account_api_AccountLocationOutput: {
        keyFields: false,
        merge: true,
      },
      AccountMutation: {
        keyFields: [],
      },
      AccountQuery: {
        keyFields: [],
      },
      address_api_AddressesResponseOutput: {
        keyFields: false,
        merge: true,
      },
      AddressQuery: {
        keyFields: [],
      },
      AdminMutation: {
        keyFields: [],
      },
      AdminQuery: {
        keyFields: [],
      },
      AuthMutation: {
        keyFields: [],
      },
      AuthQuery: {
        keyFields: [],
      },
      CardPaymentMutation: {
        keyFields: [],
      },
      CardPaymentQuery: {
        keyFields: [],
      },
      CommentsQuery: {
        keyFields: [],
      },
      ContactMutation: {
        keyFields: [],
      },
      ContentsInsuranceMutation: {
        keyFields: [],
      },
      ContentsInsuranceQuery: {
        keyFields: [],
      },
      ContractMutation: {
        keyFields: [],
      },
      ContractQuery: {
        keyFields: [],
      },
      DocumentMutation: {
        keyFields: [],
      },
      DocumentQuery: {
        keyFields: [],
      },
      ExpensesAggregateQuery: {
        keyFields: [],
      },
      ExpensesConfigsQuery: {
        keyFields: [],
      },
      ExpensesQuery: {
        keyFields: [],
      },
      ExpensesRecordsQuery: {
        keyFields: [],
      },
      GeoQuery: {
        keyFields: [],
      },
      GetPlaces: {
        keyFields: false,
        merge: true,
      },
      GrabdataQuery: { keyFields: [] },
      InsuranceMutation: {
        keyFields: [],
      },
      InsuranceQuery: {
        keyFields: [],
      },
      InvitationMutation: {
        keyFields: [],
      },
      InvitationQuery: {
        keyFields: [],
      },
      InvoiceQuery: {
        keyFields: [],
      },
      MaintenanceMutation: {
        keyFields: [],
      },
      MaintenanceQuery: {
        keyFields: [],
      },
      messaging_api_EmbedEnrichedOutput: {
        keyFields: false,
        merge: true,
      },
      MessagingMutation: {
        keyFields: [],
      },
      MessagingQuery: {
        keyFields: [],
      },
      MessagingSubscription: {
        keyFields: [],
      },
      MortgageMutation: {
        keyFields: [],
      },
      MortgageQuery: {
        keyFields: [],
      },
      Mutation: {
        keyFields: [],
      },
      NotificationMutation: {
        keyFields: [],
      },
      OauthMutation: {
        keyFields: [],
      },
      OauthQuery: {
        keyFields: [],
      },
      offer_api_OfferConditionsOutput: {
        keyFields: false,
        merge: true,
      },
      OfferMutation: {
        keyFields: [],
      },
      OfferQuery: {
        keyFields: [],
      },
      OrderMutation: {
        keyFields: [],
      },
      OrderQuery: {
        keyFields: [],
      },
      PaymentMutation: {
        keyFields: [],
      },
      ProductsQuery: {
        keyFields: [],
      },
      property_api_GetResponseOutput: {
        keyFields: false,
        merge: true,
      },
      property_api_InvitationStatusEnrichedOutput: {
        keyFields: ["invitation_id"],
      },
      property_api_PropertyDescriptionEnrichedOutput: {
        keyFields: false,
        merge: true,
      },
      property_api_PropertyDetailsOutput: {
        keyFields: false,
        merge: true,
      },
      PropertyFinancesOutput: {
        keyFields: false,
        merge: true,
      },
      property_api_PropertyListingOutput: {
        keyFields: false,
        merge: true,
      },
      property_api_PropertyLocationOutput: {
        keyFields: false,
        merge: true,
      },
      property_api_PropertyPeopleEnrichedOutput: {
        keyFields: false,
        merge: true,
      },
      property_api_PropertyRentalOutput: {
        keyFields: false,
        merge: true,
      },
      PropertyMutation: {
        keyFields: [],
      },
      PropertyQuery: {
        keyFields: [],
      },
      Query: {
        keyFields: [],
      },
      ServicesMutation: {
        keyFields: [],
      },
      ServicesQuery: {
        keyFields: [],
      },
      SignatureMutation: {
        keyFields: [],
      },
      SignatureQuery: {
        keyFields: [],
      },
      invoice_api_ProductOutput: {
        keyFields: false,
        merge: true,
      },
      invoice_api_OrderOutput: {
        keyFields: ["products"],
        merge: true,
      },
    },
  });

  if (apolloState != null) {
    cache.restore(apolloState);
  }

  apolloClient = new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser,

    link: ApolloLink.from([
      cleanTypenameLink,
      errorLink,
      authLink,
      httpOrWsLink,
    ]),
    cache,
  });

  return apolloClient;
}
